import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import ProfileImage from "../../assets/img/profileimage.png";
import helper from "../../utils/helper";

export default function Header(props) {

  const toast = useRef();
  const [headerValue, setHeaderValue] = useState("");
  const [Username, setUserName] = useState("");
  const [companyImageUrl, setCompanyImageURL] = useState("");
  const [dropdownValue, setDropDownValue] = useState(false);
  useEffect(() => {
    if (props.EventHeaderValue === 1) {
      setHeaderValue("Event List");
    } else if (props.EventHeaderValue === 2) {
      setHeaderValue("Guest List");
    } else if (props.EventHeaderValue === 3) {
      setHeaderValue("Add Event");
    } else if (props.EventHeaderValue === 4) {
      setHeaderValue("Update Event");
    } else if (props.EventHeaderValue === 5) {
      setHeaderValue("Register User");
    }
    const userName = helper.getUserName();
    setUserName(userName);
    const companyImageUrl = helper.getCompanyImageUrl();
    setCompanyImageURL(companyImageUrl);
  }, [props.EventHeaderValue]);

  const reject = () => { };

  const logout = () => {
    confirmDialog({
      message: `Are you sure you want to logout?`,
      header: "Confirmation",
      icon: "",
      accept: () => {
        localStorage.clear();
        window.location.pathname = "/"
      },
      reject,
    });
  };
  const onDropDownMenuClick = () => {
    setDropDownValue(!dropdownValue);
  };
  return (
    <>
      <div className=" pt-0">
        <Toast ref={toast} />
        <header className="fixed-header">
          <div className="header-left" style={{ position: "relative" }}>
            {" "}
            <img src={companyImageUrl} className="header_profileimage btn-rounded-hash-header company-icon" alt=""></img>{" "}
            <span className="header_text">{headerValue}</span>
          </div>
          <div className="header-right d-none d-md-block d-lg-block">
            <span className="welcome_text">
              <img src={ProfileImage} className="header_profileimage" alt=""></img>
              Welcome {Username}
            </span>
            <Button onClick={logout} severity="warning" className="btn btn-rounded-hash-header ">
              <span className="pi pi-sign-out" style={{ justifyContent: "center", display: "flex" }}></span>
            </Button>
          </div>

          <div className="header-right d-block d-md-none d-lg-none">
            <img src={ProfileImage} className="header_profileimage btn-rounded-hash-header" alt="" onClick={() => onDropDownMenuClick(true)}></img>
            <Button
              onClick={() => {
                logout();
              }}
              severity="warning"
              className="btn btn-rounded-hash-header "
            >
              <span className="pi pi-sign-out" style={{ justifyContent: "center", display: "flex" }}></span>
            </Button>
            <div
              className={dropdownValue ? "dropdown-menu-new show" : "dropdown-menu"}
              style={{ marginRight: 30, width: 100 }}
              aria-labelledby="dropdownprofileButton"
            >
              <span> {Username}</span>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}
