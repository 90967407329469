import "primeflex/primeflex.css"; // css utility
import "primeicons/primeicons.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Password } from "primereact/password";
import "primereact/resources/primereact.css"; // core css
import "primereact/resources/themes/lara-light-indigo/theme.css"; // theme
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import "./login.css";

export default function Login() {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Login");
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    localStorage.clear();
    navigate(`/`, true);
    // window.location.pathname = "/"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const validate = () => {
    var result = true;
    if ((userName === "" || userName === null || userName === undefined) && (password === "" || password === null || password === undefined)) {
      setErrorMessage("Username and password is required for login");
      return false;
    } else if (userName === "" || userName === null || userName === undefined) {
      setErrorMessage("Username is required");
      return false;
    } else if (password === "" || password === null || password === undefined) {
      setErrorMessage("Password is required");
      return false;
    }
    return result;
  };
  const onLoginClick = () => {
    if (validate()) {
      setErrorMessage("");
      setLoading(true);
      setButtonLabel("Logging in");
      if ((userName !== "" || userName !== null || userName !== undefined) && (password !== "" || password !== null || password !== undefined)) {
        api.login(userName, password).then((res) => {
          console.log(res);
          if (res?.data) {
            localStorage.setItem("isAuthenticated", res.data.isAuthenticated);
            localStorage.setItem("userName", res.data.username);
            localStorage.setItem("token", res.data.token);
            localStorage.setItem("userId", res.data.userId);
            localStorage.setItem("url", res.data.companyImageURL);
            setLoading(false);
            setButtonLabel("Login");
            window.location.pathname = "/eventList"
          } else if (res?.response?.data) {
            setLoading(false);
            setButtonLabel("Login");
            setErrorMessage("Please enter a valid username or password");
          }
        });
      } else {
        setLoading(false);
        setButtonLabel("Login");
        alert("Invalid credentials");
      }
    }
  };
  return (
    <div className="login-main">
      <div className="card">
        <div className="flex flex-column w-full">
          <div className="w-full flex flex-column gap-3 ">
            <div className="login-header text-center">
              <h2>Gift Distribution</h2>
            </div>
            <div className="flex w-full  flex flex-column gap-2">
              <div>
                <i className="fa fa-user" style={{ fontSize: "16px", marginRight: "7px" }}></i>
                <label className="">
                  Username
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
              </div>
              <InputText
                id="username"
                type="text"
                className="w-full input-field"
                value={userName}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onLoginClick();
                  }
                }}
                onChange={(e) => setUserName(e.target.value)}
                maxLength={15}
              />
            </div>
            <div className="flex w-full  flex flex-column gap-2 mb-2">
              <div>
                <i className="fa fa-key" style={{ fontSize: "16px", marginRight: "7px" }}></i>
                <label className="">
                  Password
                  <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                </label>
              </div>
              <Password
                style={{ width: "100% !important" }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    onLoginClick();
                  }
                }}
                maxLength={15}
                feedback={false}
                toggleMask
              />
            </div>
            <Button onClick={onLoginClick} className="login-btn" loading={loading} iconPos="right">
              <span className="button-lable-login">{buttonLabel}</span>
            </Button>
          </div>

          {errorMessage !== "" ? (
            <>
              <Message severity="error" text={errorMessage} />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
