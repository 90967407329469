import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import QRCode from "../../assets/img/QRCode.svg";
import Delete from "../../assets/img/delete.svg";
import Edit from "../../assets/img/editred.svg";
import api from "../../services/api";
import helper from "../../utils/helper";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
import QRCodePopUp from "../QRCodeComponent/QRCodePopUp";

export default function EventList(props) {
  const toast = useRef(null);
  const toast1 = useRef(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [eventList, setEventList] = useState([]);
  const [visibleQrCodePopUp, setVisibleQrCodePopUp] = useState(false);
  const [eventId, setEventId] = useState(0);
  const [headerValue1, setHeaderValue1] = useState("");
  const [filters, setFilters] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState(eventList);
  useEffect(() => {
    getAllEventList();
    initFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      eventName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
      verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
  };

  const getAllEventList = () => {
    const isAuthenticated = helper.getIsAuthenticated();
    if (isAuthenticated === "true") {
      setSearchValue("");
      setLoading(true);
      api.getAllEventList().then((res) => {
        if (res?.response?.status === 401) {
          window.location.pathname = "/"
        }
        setLoading(false);
        setEventList(res.data);
        setFilteredEvents(res.data);
      });
    } else {
      window.location.pathname = "/"
    }
  };

  const onInputChange = (e) => {
    setSearchValue(e.target.value);
  };
  const onEditClick = (eventId, mode) => {
    window.location.pathname = `/upsertEvent/${eventId}/${mode}`;
  };

  const deleteEvent = (eventId) => {
    api.deleteEvent(eventId).then((res) => {
      if (res?.response?.status === 401) {
        window.location.pathname = "/"
      }
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Event deleted successfully!!!",
        life: 2000,
      });
      getAllEventList();
    });
  };

  const reject = () => { };

  const confirm1 = (eventId, eventName) => {
    setEventId(eventId);
    if (eventId !== 0 || eventId !== null || eventId !== undefined) {
      confirmDialog({
        message: `Are you sure you want to delete ${eventName}?`,
        header: "Confirmation",
        icon: "",
        accept: () => {
          deleteEvent(eventId);
        },
        reject,
      });
    }
  };
  const confirmForGiftDistribution = (rowData) => {
    console.log(rowData);
    if (rowData.isEventEnd) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "The event is completed, so you will not be able to distribute the gifts.",
        life: 3000,
      });
    } else if (!rowData.isEventStarted) {
      toast1.current.show({
        severity: "info",
        summary: "Info",
        detail: "The event has not started yet, so you will not be able to distribute the gifts.",
        life: 3000,
      });
    } else {
      setEventId(rowData.eventId);
      if (eventId !== 0 || eventId !== null || eventId !== undefined) {

        randomEventGuest(rowData.eventId);

      }
    }
  };

  const printQRCode = (eventId, eventName) => {
    setEventId(eventId);
    const lines = [];
    let currentLine = "";

    for (let i = 0; i < eventName.length; i++) {
      currentLine += eventName[i];

      if ((i + 1) % 25 === 0 || i === eventName.length - 1) {
        lines.push(
          <React.Fragment key={i}>
            {currentLine}
            <br />
          </React.Fragment>
        );
        currentLine = "";
      }
    }
    setHeaderValue1(lines);
    setVisibleQrCodePopUp(true);
  };

  const randomEventGuest = (eventId) => {
    window.location.pathname = `/randomEventGuest/${eventId}`;

  };

  const onRefreshClick = () => {
    getAllEventList();
  };

  const handleMobileSearch = (e) => {
    // Filter events based on the search query
    setSearchValue(e.target.value);
    const filtered = eventList.filter(
      (event) =>
        event.eventName.toLowerCase().includes(e.target.value.toLowerCase()) || event.description.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilteredEvents(filtered);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <div className="d-flex ">
          <Button className="btn btn-rounded-hash mr-1" severity="info" onClick={() => onEditClick(rowData.eventId, "E")} title="Edit Event">
            <img src={Edit} className="edit-button" alt=""></img>
            <i></i>
          </Button>

          <div className=" flex flex-wrap gap-2 mr-1">
            <Button onClick={() => confirm1(rowData.eventId, rowData.eventName)} className="btn btn-rounded-hash" title="Delete Event">
              <img src={Delete} className="edit-button" alt=""></img>
            </Button>
          </div>
          <div className=" flex flex-wrap gap-2 mr-1">
            <Button
              onClick={() => printQRCode(rowData.eventId, rowData.eventName)}
              severity="warning"
              className="btn btn-rounded-hash"
              title="QR Code"
            >
              <img src={QRCode} className="edit-button" alt=""></img>
            </Button>
          </div>
          <div className=" flex flex-wrap gap-2 ">
            <Button
              onClick={() => confirmForGiftDistribution(rowData)}
              severity="warning"
              className="btn btn-rounded-hash"
              style={{ color: "#e72744" }}
              title="Distribute Gifts"
            >
              <span className="pi pi-gift"></span>
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  };
  let spinnerDiv = (
    <div className="card-body" style={{ textAlign: "center" }}>
      <div className="d-flex table-loading" style={{ alignItems: "center", justifyContent: "center" }}>
        <ProgressSpinner animation="border" variant="warning" style={{ width: "33px", height: "50px" }} />
        <b>Loading Events</b>
      </div>
    </div>
  );
  let tableData = (
    <>
      <div className="card-list-body-datatable">
        <DataTable
          value={eventList}
          paginator
          rows={13}
          filterClearIcon
          removableSort
          selectionMode="single"
          globalFilter={searchValue}
          stripedRows
          filters={filters}
          globalFilterFields={["eventName", "description"]}
          className="table-striped table-hover   bg-white-table p-3 event-list table-responsive"
        >
          <Column field="eventName" header="Event Name" sortable></Column>
          <Column field="description" header="Description" sortable></Column>
          <Column header="Action" body={actionBodyTemplate} />
        </DataTable>
      </div>
    </>
  );
  return (
    <>
      <main className="dashboard">
        <Toast ref={toast} />
        <Toast ref={toast1} />
        <Header EventHeaderValue={1} />
        <div className="page-body-wrapper d-none d-md-block d-lg-block">
          <div className="card main-body-border">
            <div className="page-body">
              <section className="content bg-white pt-2 pb-3 event-table">
                <div className="card-outline card-primary">
                  <div className="box">
                    <div className="box-body">
                      <>
                        {loading ? null : (
                          <>
                            <div className="">
                              <div className="row mt-2">
                                <div className="col-md-9">
                                  <span className="p-input-icon-left">
                                    <i className="pi pi-search" style={{ marginLeft: 14 }} />
                                    <InputText
                                      type="search"
                                      onInput={onInputChange}
                                      placeholder="Search by event name"
                                      className="searchbar_width"
                                      value={searchValue}
                                    />
                                  </span>
                                </div>

                                <div className="col-md-3 event_btn">
                                  <Button className="btn btn-lg btn-secondary" label="Add" icon="pi pi-plus" onClick={() => onEditClick(0, "C")} />

                                  <Button
                                    className="btn btn-primary btn_space"
                                    label=""
                                    icon="pi pi-refresh"
                                    severity="info"
                                    onClick={() => onRefreshClick()}
                                  />
                                </div>
                              </div>
                            </div>
                            <br></br>
                          </>
                        )}
                        <div>
                          {loading ? (
                            spinnerDiv
                          ) : filteredEvents.length > 0 ? (
                            tableData
                          ) : (
                            <>
                              <p
                                style={{
                                  textAlign: "center",
                                  paddingTop: "5px",
                                  color: "black",
                                }}
                              >
                                <b>Oops! No events are available for display.</b>
                              </p>
                            </>
                          )}
                        </div>
                      </>
                    </div>
                  </div>
                </div>
              </section>

              <Dialog
                header={headerValue1}
                visible={visibleQrCodePopUp}
                style={{ borderBottom: 1 }}
                onHide={() => setVisibleQrCodePopUp(false)}
                className="wrap-text"
              >
                <div className="header_border"></div>
                <QRCodePopUp
                  EventId={eventId}
                  setVisibleQrCodePopUp={() => {
                    setVisibleQrCodePopUp(false);
                  }}
                />
              </Dialog>
              <ConfirmDialog />
            </div>
          </div>
        </div>
      </main>
      {/* Mobile view */}
      <div className="d-block d-md-none d-lg-none main-screen">
        <div className="row padding-btn ">
          <div className="col-8">
            <div className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={handleMobileSearch} placeholder="Search" className="mobilesearchbar_width" value={searchValue} />
            </div>
          </div>
          <div className="col-2">
            <Button className="btn btn-lg btn-secondary-eventlist btn-w" label="" icon="pi pi-plus" onClick={() => onEditClick(0, "C")} />
          </div>

          <div className="col-2">
            <Button className="btn btn-primary-eventlist" icon="pi pi-refresh" severity="info" onClick={() => onRefreshClick()} />
          </div>
        </div>

        {loading ? (
          <div style={{ marginTop: "500px" }}>{spinnerDiv}</div>
        ) : (
          <>
            {filteredEvents.length > 0 ? (
              <>
                {filteredEvents.map((rowData, i) => {
                  return (
                    <div key={i} className="card eventlist-card mt-3">
                      <div className="card-body padding-10 ">
                        <div className="row  align-items-end ">
                          <div className="col-md-12 lh-22">
                            <span className="text-9 font-weight-600">
                              Event Name : <span className="text-12">{rowData.eventName}</span>
                            </span>
                          </div>
                        </div>
                        <div className="row align-items-end ">
                          <div className="col-md-12  lh-22">
                            <span className="text-9 font-weight-600">
                              Description : <span className="text-12">{rowData.description}</span>
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-7 col-7"></div>
                          <div className="col-md-5 col-5">
                            <div className="d-flex  justify-content-end">
                              <Button
                                className="btn btn-rounded-hash mr-1"
                                severity="info"
                                onClick={() => onEditClick(rowData.eventId, "E")}
                                title="Edit Event"
                              >
                                <img src={Edit} className="edit-button" alt=""></img>
                                <i></i>
                              </Button>

                              <Button
                                onClick={() => confirm1(rowData.eventId, rowData.eventName)}
                                title="Delete Event"
                                className="btn btn-rounded-hash mr-1"
                              >
                                <img src={Delete} className="edit-button" alt=""></img>
                              </Button>

                              <Button
                                onClick={() => printQRCode(rowData.eventId, rowData.eventName)}
                                title="Show QR code"
                                severity="warning"
                                className="btn btn-rounded-hash mr-1"
                              >
                                <img src={QRCode} className="edit-button" alt=""></img>
                              </Button>
                              <Button
                                onClick={() => confirmForGiftDistribution(rowData)}
                                severity="warning"
                                className="btn btn-rounded-hash "
                                style={{ color: "#e72744" }}
                              >
                                <span className="pi pi-gift"></span>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <>
                <p
                  style={{
                    textAlign: "center",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  <b>Oops! No events are available for display.</b>
                </p>
              </>
            )}
          </>
        )}
      </div>
      <div>
        <Footer />
      </div>

    </>
  );
}
