import React from "react";

function PageNotFound() {
    return (
        <div className="pagenotfound">
            <div className="card" style={{ backgroundColor: "#212529", color: "white" }}>
                <div style={{ padding: "20px" }}>
                    <h1>404</h1>
                    <h2>Oops! This Page Could Not Be Found</h2>
                </div>

            </div>
        </div>
    );
}

export default PageNotFound;