import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import Footer from "../Common/Footer";

export default function EventGuest(props) {
  // const navigate = useNavigate();
  const toast = useRef(null);
  const { eventId } = useParams();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [companyImageURL, setCompanyImageURL] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [detailsloading, setDetailsLoading] = useState(false);
  const [eventName, setEventName] = useState("");
  const [isDataAvailable, setIsDataAvailable] = useState(false);
  const [isDataAvailableMessage, setIsDataAvailableMessage] = useState("Oops! No event are available for display.");
  useEffect(() => {

    getEventByEventIdForGuest(eventId);
  }, [eventId]);

  const getEventByEventIdForGuest = async (eventId) => {

    setDetailsLoading(true);
    api.getEventByEventIdForGuest(eventId).then((res) => {
      if (res?.data) {
        setCompanyImageURL(res.data.companyImageURL);
        setEventName(res.data.eventName);
        if (res.data.isEventEnd) {
          setIsDataAvailable(false);
          setIsDataAvailableMessage("The event is completed, so you can't register for this event.");
        } else if (res.data.active === false) {
          setCompanyImageURL(res.data.companyImageURL);
          setEventName(res.data.eventName);
          setIsDataAvailable(false);
          setIsDataAvailableMessage("The event is not exist.");
        }
        else {
          setIsDataAvailableMessage("");
          setIsDataAvailable(true);
        }
      } else if (res?.response?.data) {
        setIsDataAvailable(false);
        setIsDataAvailableMessage("Oops! No event are available for display.");
      }
      setDetailsLoading(false);
    });
  };

  const onFirstNameChange = (event) => {
    const filteredValue = event.target.value.replace(/^\s+/, "");
    setFirstName(filteredValue);
  };
  const onLastNameChange = (event) => {
    const filteredValue = event.target.value.replace(/^\s+/, "");
    setLastName(filteredValue);
  };
  const onMobileNumberChange = (event) => {
    const filteredValue = event.target.value.replace(/[^0-9]/g, "");
    setMobileNumber(filteredValue);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };
  const validate = () => {
    var result = true;
    if (firstName === "" || firstName === null || firstName === undefined) {
      setErrorMessage("First name is required");
      return false;
    } else if (lastName === "" || lastName === null || lastName === undefined) {
      setErrorMessage("Last name is required");
      return false;
    } else if (
      (email === "" || email === null || email === undefined) &&
      (mobileNumber === "" || mobileNumber === null || mobileNumber === undefined)
    ) {
      setErrorMessage("Email Or Mobile Number is required");
      return false;
    } else if (email !== "") {
      if (!isValidEmail(email)) {
        setErrorMessage("Enter a valid Email");
        return false;
      } else {
        if (mobileNumber.length > 0 && mobileNumber.length < 10) {
          setErrorMessage("Mobile number should contain 10 digits");
          return false;
        }
      }
    } else if (mobileNumber.length > 0 && mobileNumber.length < 10) {
      setErrorMessage("Mobile number should contain 10 digits");
      return false;
    } else if (/(\d)\1{5,}/.test(mobileNumber)) {
      setErrorMessage("Mobile number is invalid");
      return false;
    }
    return result;
  };
  const createGuest = async () => {
    setLoading(true);
    let data = JSON.stringify({
      eventId: eventId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      mobileNumber: mobileNumber.toString(),
    });
    api.insertGuest(data).then((res) => {
      if (res?.data) {
        setLoading(false);
        setFirstName("");
        setLastName("");
        setEmail("");
        setMobileNumber("");
        setErrorMessage("");
        onSuccessClick();
      } else if (res?.response?.data) {
        setLoading(false);
        setErrorMessage(res?.response?.data);
      }
    });
  };
  const onSuccessClick = () => {
    setErrorMessage("");
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Registration Successful!!!",
      life: 2000,
    });
    setTimeout(() => {
      window.location.pathname = "/thankyou"
    }, 2000);
  };
  const onSubmitClick = () => {
    if (validate()) {
      setErrorMessage("");
      createGuest();
    }
  };
  const footerContent = (
    <>
      <div className="mt-5">
        <Button loading={loading} label="Submit" icon="pi pi-check" onClick={onSubmitClick} autoFocus className="btn btn-lg btn-secondary" />
      </div>
      <br></br>
      {errorMessage !== "" ? <Message severity="error" text={errorMessage} /> : <></>}
    </>
  );

  let spinnerDiv = (
    <div style={{ textAlign: "center", padding: "215px" }}>
      <div className="d-flex table-loading" style={{ paddingTop: "5px" }}>
        <ProgressSpinner animation="border" variant="warning" style={{ width: "33px", height: "50px", marginTop: "-16px" }} />
        <b>Please wait...</b>
      </div>
    </div>
  );
  return (
    <>
      <div>
        <Toast ref={toast} />
        <>
          {detailsloading ? (
            <>{spinnerDiv}</>
          ) : (
            <>
              <div className="header">
                <div className="col-12" style={{ position: "relative" }}>
                  <img src={companyImageURL} className="header_profileimage btn-rounded-hash-header company-icon-eventguest" alt=""></img>
                  <span className="header_text">{eventName} </span>
                </div>
              </div>

              {isDataAvailable ? (
                <>

                  <div className="card main-body-border-eventguestpage">
                    <div className="card-body width">
                      <div className="row" style={{ justifyContent: "center" }}>
                        <span className="header_text_Name mb-3">Register here to receive your Gift</span>
                      </div>
                      <br />
                      <div className="row ">
                        <div className="col-md-6">
                          <label className="lable-css text-color-black">
                            First Name <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                          </label>
                          <InputText id="firstName" className="input-box" onChange={(e) => onFirstNameChange(e)} value={firstName} maxLength={50} />
                        </div>
                        <div className="col-md-6">
                          <label className="lable-css text-color-black mobile-lable">
                            Last Name <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                          </label>
                          <InputText id="lastName" className="input-box" onChange={(e) => onLastNameChange(e)} value={lastName} maxLength={50} />
                        </div>
                      </div>
                      <br></br>
                      <div className="row ">
                        <div className="col-md-6">
                          <label className="lable-css text-color-black">
                            Email
                            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                          </label>
                          <InputText id="email" className="input-box" onChange={(e) => setEmail(e.target.value)} value={email} maxLength={75} />
                        </div>

                        <div className="col-md-6">
                          <label className="lable-css text-color-black mobile-lable">
                            Mobile Number
                            <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                          </label>
                          <InputText
                            keyfilter="int"
                            id="mobileNumber"
                            className="input-box"
                            onChange={(e) => onMobileNumberChange(e)}
                            min={0}
                            value={mobileNumber}
                            maxLength={10}
                          />
                        </div>
                      </div>
                      {footerContent}
                    </div>
                  </div>

                </>
              ) : (
                <>
                  <p
                    style={{
                      textAlign: "center",
                      paddingTop: "64px",
                      color: "black",
                    }}
                  >
                    <b>{isDataAvailableMessage}</b>
                  </p>
                </>
              )}
            </>
          )}
        </>
      </div>
      <div style={{ position: "absolute", left: "0", right: "0" }}>
        <Footer />
      </div>
    </>
  );
}
