import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";

export default function QRCodePopUp(props) {
  useEffect(() => {
    if (props.EventId !== 0) {
      generateQRCodeLink(props.EventId);
    }
  }, [props.EventId]);
  const [qrvalue, setQrCodeURL] = useState("");

  const generateQRCodeLink = (eventId) => {
    const qrvalue = process.env.REACT_APP_URL + `eventGuest/${eventId}`;

    setQrCodeURL(qrvalue);
  };
  const footerContent = (
    <div>
      <div className="row padding_QRcode">
        <div className="col-12 QRcode_position">
          <div className="poweredby-text">Powered by</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 QRcode_position pt-0">
          <img src="https://arieotech.com/wp-content/uploads/2022/05/blue-black-logo.svg" className="img_size_QRcode" alt=""></img>
        </div>
      </div>
    </div>
  );
  return (
    <>
      <div style={{ height: "auto", padding: 12, maxWidth: 250, width: "100%" }}>
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={qrvalue}
          viewBox={`0 0 256 256`}
          imageSettings={{
            src: "https://d3tyhavdb1x2td.cloudfront.net/Logos/arieotechLogo.png",
            excavate: true,
          }}
        />
      </div>
      <div className="header_border"></div>
      {footerContent}
    </>
  );
}
