import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { ConfirmDialog } from "primereact/confirmdialog";
import { DataTable } from "primereact/datatable";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import helper from "../../utils/helper";
import Footer from "../Common/Footer";
import Header from "../Common/Header";
export default function UpsertEvent() {
  const { eventId } = useParams();
  const { mode } = useParams();
  let today = new Date();
  const toast = useRef(null);
  const [headerValue, setHeaderValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [eventName, setEventName] = useState("");
  const [description, setDescription] = useState("");
  const [randomGuestCount, setRandomGuestCount] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [eventStartOn, setEventStartOn] = useState(null);
  const [eventEndOn, setEventEndOn] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [listLoading, setListLoading] = useState(false);
  const [guestList, setGuestList] = useState([false]);
  const [filters, setFilters] = useState(null);
  const [filteredGuests, setFilteredGuests] = useState(guestList);
  const [invalidClassNameForEventName, setInvalidClassNameForEventName] = useState(false);
  const [invalidClassNameForDescription, setInvalidClassNameForDescription] = useState(false);
  const [invalidClassNameForEventStartOn, setInvalidClassNameForEventStartOn] = useState(false);
  const [invalidClassNameForEventEndOn, setInvalidClassNameForEventEndOn] = useState(false);
  const [invalidClassNameForRandomGuestCount, setInvalidClassNameForRandomGuestCount] = useState(false);
  useEffect(() => {
    if (eventId !== "0") {
      const userId = helper.getUserId();
      getEventByEventId(eventId, userId);
      getGuestListByEventId(eventId);
      initFilters();
    }
    if (mode === "E") {
      setHeaderValue(4);
    } else {
      setHeaderValue(3);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, mode]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      firstName: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
      activity: { value: null, matchMode: FilterMatchMode.BETWEEN },
      verified: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
  };

  const onEventNameChange = (event) => {
    const filteredValue = event.target.value.replace(/^\s+/, "");
    setEventName(filteredValue);
  };
  const onDescriptionChange = (event) => {
    const filteredValue = event.target.value.replace(/^\s+/, "");
    setDescription(filteredValue);
  };
  const getGuestListByEventId = async (eventId) => {
    const isAuthenticated = helper.getIsAuthenticated();
    if (isAuthenticated === "true") {
      setSearchValue("");
      setListLoading(true);
      api.getGuestListByEventId(eventId).then((res) => {
        if (res?.response?.status === 401) {
          window.location.pathname = "/"
        }
        setListLoading(false);
        res.data.forEach((element) => {
          if (element.isGiftSend) {
            element.isGiftSend = "Gift Sent";
          } else {
            element.isGiftSend = "Waiting to Send";
          }
        });
        setGuestList(res.data);
        setFilteredGuests(res.data);
      });
    } else {
      window.location.pathname = "/"
    }
  };

  const getEventByEventId = async (eventId, userId) => {
    setLoading(true);

    api.getEventByEventId(eventId, userId).then((res) => {
      if (res?.response?.status === 401 || res?.response?.status === 400) {
        window.location.pathname = "/pagenotfound"
      } else {
        setEventName(res.data.eventName);
        setDescription(res.data.description);
        const dateStartOn = res.data.eventStartOn;
        const dateStartOnObject = new Date(dateStartOn);
        setEventStartOn(dateStartOnObject);
        const dateEndOn = res.data.eventEndOn;
        const dateEndOnObject = new Date(dateEndOn);
        setEventEndOn(dateEndOnObject);
        setRandomGuestCount(res.data.randomGuestCount);
        setLoading(false);
      }
    });
  };
  const onSuccessClick = (eventId) => {
    setSubmitLoading(false);
    if (eventId === 0) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Event created successfully!!!",
        life: 2000,
      });
    } else {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Event updated successfully!!!",
        life: 2000,
      });
    }
    setTimeout(() => {
      window.location.pathname = "/eventList"
    }, 2000);
  };
  const resetErrorMessage = () => {
    setInvalidClassNameForEventName(false);
    setInvalidClassNameForDescription(false);
    setInvalidClassNameForEventStartOn(false);
    setInvalidClassNameForEventEndOn(false);
    setInvalidClassNameForRandomGuestCount(false);
    setErrorMessage("");
  };
  const validate = () => {
    var result = true;
    if (eventName === "" || eventName === null || eventName === undefined) {
      setInvalidClassNameForEventName(true);
      setInvalidClassNameForDescription(false);
      setInvalidClassNameForEventStartOn(false);
      setInvalidClassNameForEventEndOn(false);
      setInvalidClassNameForRandomGuestCount(false);
      setErrorMessage("Event Name is required");
      return false;
    } else if (description === "" || description === null || description === undefined) {
      setInvalidClassNameForEventName(false);
      setInvalidClassNameForDescription(true);
      setInvalidClassNameForEventStartOn(false);
      setInvalidClassNameForEventEndOn(false);
      setInvalidClassNameForRandomGuestCount(false);
      setErrorMessage("Description is required");
      return false;
    } else if (eventStartOn === "" || eventStartOn === null || eventStartOn === undefined) {
      setInvalidClassNameForEventName(false);
      setInvalidClassNameForDescription(false);
      setInvalidClassNameForEventStartOn(true);
      setInvalidClassNameForEventEndOn(false);
      setInvalidClassNameForRandomGuestCount(false);
      setErrorMessage("Event start date is required");
      return false;
    } else if (eventEndOn === "" || eventEndOn === null || eventEndOn === undefined) {
      setInvalidClassNameForEventName(false);
      setInvalidClassNameForDescription(false);
      setInvalidClassNameForEventStartOn(false);
      setInvalidClassNameForRandomGuestCount(false);
      setInvalidClassNameForEventEndOn(true);
      setErrorMessage("Event end date is required");
      return false;
    } else if (randomGuestCount === "" || randomGuestCount === null || randomGuestCount === undefined) {
      setInvalidClassNameForEventName(false);
      setInvalidClassNameForDescription(false);
      setInvalidClassNameForEventStartOn(false);
      setInvalidClassNameForEventEndOn(false);
      setInvalidClassNameForRandomGuestCount(true);
      setErrorMessage("Random guest count is required");
      return false;
    }
    return result;
  };
  const createEvent = async () => {
    if (validate()) {
      resetErrorMessage();
      setSubmitLoading(true);
      var startOn = moment(eventStartOn).format("YYYY-MM-DD HH:mm:s a");
      var endOn = moment(eventEndOn).format("YYYY-MM-DD HH:mm:ss a");
      let data = JSON.stringify({
        eventName: eventName,
        description: description,
        eventStartOn: startOn,
        eventEndOn: endOn,
        randomGuestCount: randomGuestCount,
      });

      api.insertEvent(data).then((res) => {
        if (res?.response?.status === 401) {
          window.location.pathname = "/"
        }
        setSubmitLoading(true);
        onSuccessClick(0);
      });
    }
  };
  const updateEvent = async () => {
    if (validate()) {
      setSubmitLoading(true);
      resetErrorMessage();
      var startOn = moment(eventStartOn).format("YYYY-MM-DD HH:mm:s a");
      var endOn = moment(eventEndOn).format("YYYY-MM-DD HH:mm:ss a");
      let data = JSON.stringify({
        eventName: eventName,
        description: description,
        eventStartOn: startOn,
        eventEndOn: endOn,
        randomGuestCount: randomGuestCount,
      });
      api.updateEvent(data, eventId).then((res) => {
        if (res?.response?.status === 401) {

        }
        setSubmitLoading(true);
        onSuccessClick(eventId);
      });
    }
  };
  const onSubmitClick = () => {
    if (eventId === "0") {
      createEvent();
    } else {
      updateEvent();
    }
  };
  const onCancelClick = () => {
    window.location.pathname = "/eventList"
  };

  const onInputChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleMobileSearch = (e) => {
    // Filter events based on the search query
    setSearchValue(e.target.value);
    const filtered = guestList.filter(
      (guest) =>
        guest.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        guest.lastName.toLowerCase().includes(e.target.value.toLowerCase()) ||
        guest.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        guest.mobileNumber.toLowerCase().includes(e.target.value.toLowerCase())
    );

    setFilteredGuests(filtered);
  };

  const onChangeEventStartDate = (date) => {
    setEventEndOn(null);
    setEventStartOn(date);
  };

  const onChangeEventEndDate = (date) => {
    setEventEndOn(date);
  };

  const footerContent = (
    <div className="mt-5">
      <Button label="Submit" icon="pi pi-check" onClick={onSubmitClick} autoFocus loading={submitLoading} className="btn btn-lg btn-secondary" />
      <Button label="Cancel" icon="pi pi-times" onClick={() => onCancelClick()} className="btn btn-primary btn_space" />
      <br />
      <br />
      {errorMessage !== "" ? <Message severity="error" text={errorMessage} /> : <></>}
    </div>
  );
  let spinnerDiv = (
    <div className="card-body" style={{ textAlign: "center" }}>
      <div className="d-flex table-loading" style={{ alignItems: "center", justifyContent: "center" }}>
        <ProgressSpinner animation="border" variant="warning" style={{ width: "33px", height: "50px" }} />
        <b>Loading Event details</b>
      </div>
    </div>
  );

  let guestSpinnerDiv = (
    <div className="card-body" style={{ textAlign: "center" }}>
      <div className="d-flex table-loading" style={{ alignItems: "center", justifyContent: "center" }}>
        <ProgressSpinner animation="border" variant="warning" style={{ width: "33px", height: "50px" }} />
        <b>Loading Guests</b>
      </div>
    </div>
  );
  let tableData = (
    <>
      <div className="card-list-body-datatable">
        <DataTable
          value={guestList}
          paginator
          filterClearIcon
          rows={5}
          removableSort
          selectionMode="single"
          globalFilter={searchValue}
          stripedRows
          filters={filters}
          globalFilterFields={["firstName", "lastName", "email", "mobileNumber"]}
          className="table-striped table-hover   bg-white-table p-3 event-list table-responsive"
        >
          <Column field="firstName" header="First Name" sortable></Column>
          <Column field="lastName" header="Last Name" sortable></Column>
          <Column field="email" header="Email" sortable></Column>
          <Column field="mobileNumber" header="Mobile Number" sortable></Column>
          <Column field="isGiftSend" header="Gift Status" sortable></Column>
        </DataTable>
      </div>
    </>
  );
  return (
    <>
      <main className="dashboard">
        <Toast ref={toast} />
        <ConfirmDialog />
        <Header EventHeaderValue={headerValue} />
        <div className="upsertevent_padding">
          <div className="card main-body-border">
            {loading ? (
              spinnerDiv
            ) : (
              <>
                <div className="card-body width">
                  <div className="row ">
                    <div className="col-md-6">
                      <label className="lable-css text-color-black">
                        Event Name
                        <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                      </label>
                      <InputText
                        type="text"
                        placeholder="Enter event name"
                        id="eventName"
                        value={eventName}
                        onChange={(e) => {
                          onEventNameChange(e);
                        }}
                        className={`input-text ${invalidClassNameForEventName ? "p-invalid" : ""}`}
                        maxLength={20}
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="lable-css mobile-lable text-color-black">
                        Description
                        <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                      </label>
                      <InputText
                        type="text"
                        placeholder="Enter description"
                        id="description"
                        value={description}
                        onChange={(e) => {
                          onDescriptionChange(e);
                        }}
                        className={`input-text ${invalidClassNameForDescription ? "p-invalid" : ""}`}
                        maxLength={250}
                      />
                    </div>
                  </div>
                  <br></br>
                  <div className="row ">
                    <div className="col-md-6">
                      <label className="lable-css text-color-black">
                        Event Start Date
                        <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                      </label>
                      <Calendar
                        placeholder="Select event start date"
                        value={eventStartOn}
                        onChange={(e) => onChangeEventStartDate(e.value)}
                        minDate={today}
                        readOnlyInput
                        className={`input-text ${invalidClassNameForEventStartOn ? "p-invalid" : ""}`}
                        style={{
                          width: "100%",
                          fontSize: "14px",
                        }}
                        showIcon
                        showTime
                      />
                    </div>
                    <div className="col-md-6">
                      <label className="lable-css mobile-lable text-color-black">
                        Event End Date
                        <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                      </label>

                      <Calendar
                        placeholder="Select event end date"
                        minDate={eventStartOn}
                        value={eventEndOn}
                        onChange={(e) => onChangeEventEndDate(e.value)}
                        readOnlyInput
                        className={`input-text ${invalidClassNameForEventEndOn ? "p-invalid" : ""}`}
                        showIcon
                        showTime
                      />
                    </div>
                  </div>
                  <br></br>
                  <div className="row ">
                    <div className="col-md-6">
                      <label className="lable-css text-color-black">
                        Random Guest Count
                        <span style={{ color: "red", marginLeft: "3px" }}>*</span>
                      </label>
                      <InputNumber
                        placeholder="Enter count of people you want to distribute the gifts to"
                        id="randomGuestCount"
                        value={randomGuestCount}
                        onValueChange={(e) => setRandomGuestCount(e.target.value)}
                        className={`input-text ${invalidClassNameForRandomGuestCount ? "p-invalid" : ""}`}
                        min={1}
                        max={5}
                        showButtons
                      />
                    </div>
                  </div>
                  {footerContent}
                </div>
              </>
            )}
          </div>
        </div>
        {eventId !== "0" ? (
          <div className=" d-none d-md-block d-lg-block">
            <div className="card main-body-border mb-3">
              <div className="row ">
                <div className="col-md-6 header-join-guest-list text-color-black">
                  <h5>Event Joined Guest List</h5>
                </div>
                <div className="col-md-6 searchbox mt-3">
                  <span className="p-input-icon-left">
                    <i className="pi pi-search" style={{ marginLeft: 14 }} />
                    <InputText type="search" onInput={onInputChange} placeholder="Search" className="searchbar_width" value={searchValue} />
                  </span>
                </div>
              </div>
              <section className="content bg-white pt-2 pb-3 event-table">
                <div className="card-outline card-primary">
                  <div className="box">
                    <div className="box-body">
                      <>
                        {listLoading ? (
                          guestSpinnerDiv
                        ) : (
                          <>
                            {guestList.length > 0 ? (
                              tableData
                            ) : (
                              <>
                                <p
                                  style={{
                                    textAlign: "center",
                                    paddingTop: "5px",
                                    color: "black",
                                  }}
                                >
                                  <b>Oops! No guests are available for display.</b>
                                </p>
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        ) : (
          <></>
        )}
      </main>
      {/* Mobile view */}
      {eventId !== "0" ? (
        <div className="d-block d-md-none d-lg-none main-screen">
          <div className="row" style={{ backgroundColor: "#e5ebfa", justifyContent: "center" }}>
            <div className="EventGuestList-title">
              Event Joined Guest List
            </div>
          </div>

          <div className="row padding-search-guestlist mx-auto" >
            <div className="col-12">
              <span className="p-input-icon-left " style={{ width: "100%" }}>
                <i className="pi pi-search" />
                <InputText
                  type="search"
                  onInput={handleMobileSearch}
                  placeholder="Search"
                  className="mobilesearchbar_width_eventguestlist"
                  value={searchValue}
                />
              </span>
            </div>
          </div>

          {listLoading ? (
            <div style={{ marginTop: "500px" }}>{guestSpinnerDiv}</div>
          ) : (
            <>
              {filteredGuests.length > 0 ? (
                <>
                  {filteredGuests.map((rowData, i) => {
                    return (
                      <div key={i} className="card eventlist-card mt-3">
                        <div className="card-body padding-10 ">
                          <div className="row  align-items-end ">
                            <div className="col-md-12 lh-22">
                              <span className="text-9 font-weight-600">
                                First Name : <span className="text-12">{rowData.firstName}</span>
                              </span>
                            </div>
                          </div>
                          <div className="row align-items-end ">
                            <div className="col-md-12  lh-22">
                              <span className="text-9 font-weight-600">
                                Last Name : <span className="text-12">{rowData.lastName}</span>
                              </span>
                            </div>
                          </div>
                          <div className="row align-items-end ">
                            <div className="col-md-12  lh-22">
                              <span className="text-9 font-weight-600">
                                Email : <span className="text-12">{rowData.email}</span>
                              </span>
                            </div>
                          </div>
                          <div className="row align-items-end ">
                            <div className="col-md-12  lh-22">
                              <span className="text-9 font-weight-600">
                                Mobile Number : <span className="text-12">{rowData.mobileNumber}</span>
                              </span>
                            </div>
                          </div>
                          <div className="row align-items-end ">
                            <div className="col-md-12  lh-22">
                              <span className="text-9 font-weight-600">
                                Gift Status : <span className="text-12">{rowData.isGiftSend}</span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  <p
                    style={{
                      textAlign: "center",
                      paddingTop: "5px",
                      color: "black",
                    }}
                  >
                    <b>Oops! No guests are available for display.</b>
                  </p>
                </>
              )}
            </>
          )}
        </div>
      ) : (
        <></>
      )}
      <div>
        <Footer />
      </div>

    </>
  );
}
