class helper {
  static getToken = () => {
    return localStorage.getItem("token");
  };
  static getUserName = () => {
    return localStorage.getItem("userName");
  };
  static getIsAuthenticated = () => {
    return localStorage.getItem("isAuthenticated");
  };
  static getUserId = () => {
    return localStorage.getItem("userId");
  };
  static getCompanyImageUrl = () => {
    return localStorage.getItem("url");
  };
}

export default helper;
