import "primeicons/primeicons.css";
import "primereact/resources/primereact.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/AccountManagement/Login";
import ThankYou from "./components/AcknowledgeManagement/ThankYou";
import PageNotFound from "./components/Common/PageNotFound";
import EventList from "./components/Event/EventList";
import UpsertEvent from "./components/Event/UpsertEvent";
import EventGuest from "./components/Guest/EventGuest";
import RandomEventGuestList from "./components/Guest/RandomEventGuestList";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/eventList" element={<EventList />}></Route>
        <Route path="/eventGuest/:eventId" element={<EventGuest />}></Route>
        <Route path="/randomEventGuest/:eventId" element={<RandomEventGuestList />}></Route>
        <Route path="/upsertEvent/:eventId/:mode" element={<UpsertEvent />}></Route>
        <Route path="/thankyou" element={<ThankYou />}></Route>
        <Route path="/pagenotfound" element={<PageNotFound />}></Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
