import axios from "axios";
import helper from "../utils/helper";
const api = {
  login: async (userName, password) => {
    const URL = process.env.REACT_APP_USER_API + `Login?username=${userName}&password=${password}`;
    return await axios
      .get(URL)
      .then((res) => res)
      .catch((err) => err);
  },

  getAllEventList: async () => {
    const token = helper.getToken();
    const URL = process.env.REACT_APP_EVENT_API + `GetEventListByUserId`;
    return await axios
      .get(URL, {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  },

  getEventByEventId: async (eventId) => {
    const token = helper.getToken();
    const URL = process.env.REACT_APP_EVENT_API + `GetEventByEventId?eventId=${eventId}`;
    return await axios
      .get(URL, {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  },

  getEventByEventIdForGuest: async (eventId) => {
    const URL = process.env.REACT_APP_EVENT_API + `GetEventByEventIdForGuest?eventId=${eventId}`;
    return await axios
      .get(URL)
      .then((res) => res)
      .catch((err) => err);
  },

  insertEvent: async (data) => {
    const token = helper.getToken();
    const URL = process.env.REACT_APP_EVENT_API + `InsertEvent?userId=${token}`;
    return await axios
      .post(URL, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(console.log)
      .catch(console.log);
  },

  updateEvent: async (data, eventId) => {
    const token = helper.getToken();
    const URL = process.env.REACT_APP_EVENT_API + `UpdateEvent?eventId=${eventId}`;
    return await axios
      .put(URL, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(console.log)
      .catch(console.log);
  },

  deleteEvent: async (eventId) => {
    const token = helper.getToken();
    const URL = process.env.REACT_APP_EVENT_API + `DeleteEventByEventId?eventId=${eventId}`;
    return await axios
      .delete(URL, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(console.log)
      .catch(console.log);
  },

  getGuestListByEventId: async (eventId) => {
    const token = helper.getToken();
    const URL = process.env.REACT_APP_GUEST_API + `GetGuestListByEventId?eventId=${eventId}`;
    return await axios
      .get(URL, {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  },

  getRandomGuestsByEventId: async (eventId) => {
    const token = helper.getToken();
    const URL = process.env.REACT_APP_GUEST_API + `GetRandomGuestsByEventId?eventId=${eventId}`;
    return await axios
      .get(URL, {
        headers: {
          "Cache-Control": "no-cache, no-store, must-revalidate",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res)
      .catch((err) => err);
  },

  insertGuest: async (data) => {
    const URL = process.env.REACT_APP_GUEST_API + `InsertGuest`;
    return await axios
      .post(URL, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((res) => res)
      .catch((err) => err);
  },
};
export default api;
