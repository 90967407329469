import React from "react";
export default function Footer() {
  return (
    <div className="footer">
      <span className="poweredby-text-footer">Powered by </span>
      <a style={{ cursor: "pointer" }} href="https://arieotech.com/" target="_blank" rel="noreferrer">
        <img src="https://arieotech.com/wp-content/uploads/2021/09/blue-white-logo.svg" className="img-size-footer" alt=""></img>
      </a>
    </div>
  );
}
