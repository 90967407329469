import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import helper from "../../utils/helper";
import Footer from "../Common/Footer";
import Header from "../Common/Header";

export default function RandomEventGuestList(props) {

  const { eventId } = useParams();
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [guestList, setGuestList] = useState([]);
  // eslint-disable-next-line

  useEffect(() => {

    confirmDialog({
      message: `Are you sure you want to distribute the gifts?`,
      header: "Confirmation",
      icon: "",
      accept: () => {
        if (eventId !== "0") {
          getRandomGuestsByEventId();
        } else {
          window.location.pathname = "/eventList";
        }
      },
      reject,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const reject = () => { window.location.pathname = "/eventList"; };

  const getRandomGuestsByEventId = () => {
    const isAuthenticated = helper.getIsAuthenticated();
    if (isAuthenticated === "true") {
      setLoading(true);
      api.getRandomGuestsByEventId(eventId).then((res) => {
        if (res?.response?.status === 401) {
          window.location.pathname = "/";
        }
        const loadingTimeout = setTimeout(() => {
          setLoading(false);
        }, 2000);

        setGuestList(res.data);
        return () => clearTimeout(loadingTimeout);
      });
    } else {
      window.location.pathname = "/";
    }
  };

  const onBackClick = () => {
    localStorage.removeItem("apiCalled");
    window.location.pathname = "/eventList";
  };

  let spinnerDiv = <div className="loader-container"></div>;

  return (
    <>
      <main className="dashboard">
        <Toast ref={toast} />
        <ConfirmDialog />
        <Header EventHeaderValue={2} />
        <div className="page-body-wrapper d-md-block d-lg-block">
          <div className="page-body">
            <>
              {loading ? null : (
                <>
                  <div className="container">
                    <div className="row">
                      <div className="col-md-9"></div>
                      <div className="col-md-3"></div>
                    </div>
                  </div>
                </>
              )}

              <div>
                {loading ? (
                  spinnerDiv
                ) : (
                  <>
                    <div className="card main-body-border">
                      <div className="page-body">
                        <section className="content bg-white pb-3 event-table">
                          <div className="card-outline card-primary">
                            <div className="box">
                              <div className="box-body">
                                {guestList.length > 0 ? (
                                  <div className="randomguest-container">
                                    <Button
                                      className="btn btn-primary btn_space position-backbtn"
                                      label=""
                                      icon="pi pi-backward"
                                      severity="info"
                                      onClick={() => onBackClick()}
                                    />
                                    <div className=" justify-content-center">
                                      {guestList.map((guest, i) => {
                                        return (
                                          <div key={i}>
                                            <Card title={guest.firstName + " " + guest.lastName} className="md:w-25rem card-css"></Card>
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                ) : (
                                  <>
                                    <p
                                      style={{
                                        textAlign: "center",
                                        paddingTop: "5px",
                                        color: "black",
                                      }}
                                    >
                                      <b>Oops ! No guests available to distribute Gifts.</b>
                                    </p>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          </div>
        </div>

        <div>
          <Footer />
        </div>
      </main>
    </>
  );
}
